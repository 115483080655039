<style scoped>
img.spinner {
  width: 20px;
  margin-right: 10px;
}
</style>
<template>
  <modal name="marcasEdit" height="auto" width="900" :scrollable="true" :clickToClose="false">
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
      <CTabs variant="tabs" class="nav-tabs-boxed">
        <CTab :title="`Editando ${descSingular}`">
          <div class="xrow">            
            <div class="col-2-3">
              <div class="form-group">
                <label for="">Link</label>
                <input class="form-control" v-model="item.link"/>
              </div>
            </div>            
            <div class="col-1-3">
              <div class="form-group">
                <label for="">Ordem</label>
                <input type="number" class="form-control" v-model="item.ordem"/>
                <div class="message">{{ validation.firstError('item.ordem') }}</div>
              </div>
            </div>
            
            <div class="col-2-3 add-image">
              <label>
                Adicionar Imagem
                <image-picker
                  label="Imagem 145x92"
                  placeholder="Colar URL ou clique ao lado para upload"
                  v-model="item.imagem"
                />
              </label>
            </div>
            <div class="col-1-3">
              <div class="form-group">
                <label for="">Vísivel Menu</label>
                <select class="form-control" v-model="item.ativo">
                  <option value="1">Sim</option>
                  <option value="0">Não</option>
                </select>
                <div class="message">{{ validation.firstError('item.ativo') }}</div>
              </div>
            </div>
          </div>
          
          
          <div class="xrow edit-buttons">
            <button :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }" v-on:click="handleSubmit">
              Alterar
            </button>
            &nbsp;
            <button class="button" v-on:click="close">Fechar</button>
          </div>
        </CTab>
      </CTabs>
      </div>
    </div>
  </modal>
</template>
<script>
import { Validator } from "simple-vue-validator";
import _findIndex from "lodash/findIndex";
import _remove from "lodash/remove";
import spinner from "../../assets/images/spinner.svg";
import CloseModal from "../../components/CloseModal";
import ImagePicker from "../../components/ImagePicker";


export default {
  name: "marcasEdit",
  props: [
    "item",
    "access",    
    "update",
    "close",
    "modulos",    
    "descSingular",
  ],
  components: { CloseModal, ImagePicker },
  data() {
    return {
      loadingModulos: [],
      spinner,      
    };
  },
  computed: {
    pivots() {
      if (this.access && this.access.perfisModulos) {
        return this.access.perfisModulos.reduce((acc, pivot) => {
          acc.push(pivot.modulo_id);
          return acc;
        }, []);
      }
      return [];
    },
  },
  validators: {
    "item.ativo": (value) => Validator.value(value).required(),
    "item.ordem": (value) => Validator.value(value).required(),
  },
  methods: {
    isModuloIncluso(modulo) {
      return this.pivots.indexOf(+modulo.id) >= 0;
    },
    isLoadingModulo(modulo) {
      return this.loadingModulos.indexOf(modulo.id) >= 0;
    },
    removeLoadingModulo(modulo) {
      let idx = this.loadingModulos.indexOf(modulo.id);
      if (idx >= 0) {
        this.loadingModulos.splice(idx, 1);
      }
    },
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.update();
        }
      });
    },
  },
};
</script>
